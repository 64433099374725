// BORDER

.border-radius {
  border-radius: 1rem;
}

.border-radius-1 {
  border-radius: 0.5rem;
}

.border-radius-2 {
  border-radius: 0.75rem;
}

.border-radius-3 {
  border-radius: 1rem;
}

.border-radius-4 {
  border-radius: 1.5rem;
}

.border-radius-5 {
  border-radius: 2rem;
}

.border-radius-top-3 {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.no-border {
  border: none !important;
}

.border {
  border: 1px solid;
}

.border-bottom {
  border-bottom: 1px solid;
}

.border-top {
  border-top: 1px solid;
}

.border-color-transparent {
  border-color: transparent;
}

.pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

// BOX-SHADOW
.box-shadow-4-15 {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
}

.box-shadow-bottom-1rem {
  box-shadow: 0 1rem 1rem -1rem rgba(0, 0, 0, 0.15);
}

// SCROLL

.scroll-smooth {
  scroll-behavior: smooth;
}
