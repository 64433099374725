.border-radius {
  border-radius: 1rem;
}

.border-radius-1 {
  border-radius: 0.5rem;
}

.border-radius-2 {
  border-radius: 0.75rem;
}

.border-radius-3 {
  border-radius: 1rem;
}

.border-radius-4 {
  border-radius: 1.5rem;
}

.border-radius-5 {
  border-radius: 2rem;
}

.border-radius-top-3 {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.no-border {
  border: none !important;
}

.border {
  border: 1px solid;
}

.border-bottom {
  border-bottom: 1px solid;
}

.border-top {
  border-top: 1px solid;
}

.border-color-transparent {
  border-color: transparent;
}

.pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.box-shadow-4-15 {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
}

.box-shadow-bottom-1rem {
  box-shadow: 0 1rem 1rem -1rem rgba(0, 0, 0, 0.15);
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.page-container {
  padding: 4rem 1.25rem;
}

.max-mobile-view {
  max-width: 40rem;
}

.v-h-centered {
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: minmax(0, 1fr);
}

.h-centered {
  height: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
}

.touch-pan-y {
  touch-action: pan-y !important;
}

.desktop-detail-wrapper {
  max-width: 40rem;
  min-height: 100vh;
  margin: auto;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.block {
  display: block;
}

.d-none {
  display: none;
}

.layout-h-c-f {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: auto 1fr auto;
}

.layout-fr {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
}

.layout-auto-fr {
  display: grid;
  grid-template-columns: auto 1fr;
}

.layout-fr-auto {
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
}

.layout-auto-fr-auto {
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.layout-fr-fr {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.layout-fr-fr-fr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.layout-fr-fr-fr-fr {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.layout-rows-auto-fr {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
}

.span-2 {
  grid-column: span 2;
}

.span-3 {
  grid-column: span 3;
}

.align-self-start {
  align-self: start;
}

.align-self-stretch {
  align-self: stretch;
}

.align-self-center {
  align-self: center;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.span-column-2 {
  grid-column: span 2;
}

.span-row-2 {
  grid-row: span 2;
}

.align-items-start {
  align-items: start !important;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-content-start {
  justify-content: start;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 1rem;
}

.gap-4 {
  gap: 1.5rem;
}

.gap-5 {
  gap: 2rem;
}

.flex-grow-1 {
  flex-grow: 1;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.translate-middle-x {
  transform: translateX(-50%);
}

.left-0 {
  left: 0;
}

.left-50 {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: 0.25rem;
}

.right-2 {
  right: 0.5rem;
}

.right-3 {
  right: 1rem;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: 0.25rem;
}

.top-2 {
  top: 0.5rem;
}

.top-3 {
  top: 1rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-1 {
  bottom: 0.25rem;
}

.bottom-2 {
  bottom: 0.5rem;
}

.bottom-3 {
  bottom: 1rem;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.w-45 {
  width: 45%;
}

.w-60 {
  width: 60%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-fit {
  width: fit-content;
}

.w-a {
  width: auto;
}

.max-w-100 {
  max-width: 100%;
}

.h-100 {
  height: 100%;
}

.dvh-100 {
  height: 100dvh;
}

.max-h-100 {
  max-height: 100%;
}

.min-h-100 {
  min-height: 100%;
}

.min-h-100-dvh {
  min-height: 100dvh;
}

.h-fit {
  height: fit-content;
}

.object-fit-contain {
  object-fit: contain;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 1rem;
}

.p-4 {
  padding: 1.5rem;
}

.p-5 {
  padding: 2rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-3 {
  padding-top: 1rem;
}

.pt-4 {
  padding-top: 1.5rem;
}

.pt-5 {
  padding-top: 2rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-3 {
  padding-bottom: 1rem;
}

.pb-4 {
  padding-bottom: 1.5rem;
}

.pb-5 {
  padding-bottom: 2rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-4 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-5 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 1em;
  padding-bottom: 1rem;
}

.py-4 {
  padding-top: 1.5em;
  padding-bottom: 1.5rem;
}

.py-5 {
  padding-top: 2em;
  padding-bottom: 2rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-3 {
  padding-right: 1rem;
}

.pr-4 {
  padding-right: 1.5rem;
}

.pr-5 {
  padding-right: 2rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-3 {
  padding-left: 1rem;
}

.pl-4 {
  padding-left: 1.5rem;
}

.pl-5 {
  padding-left: 2rem;
}

.m-a {
  margin: auto;
}

.mx-a {
  margin-left: auto;
  margin-right: auto;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-4 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.ml-auto {
  margin-left: auto;
}

.ml-0 {
  margin-left: 0px;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 1rem;
}

.ml-4 {
  margin-left: 1.5rem;
}

.ml-5 {
  margin-left: 2rem;
}

.mr-0 {
  margin-right: 0px;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mr-4 {
  margin-right: 1.5rem;
}

.mr-5 {
  margin-right: 2rem;
}

.mt-auto {
  margin-top: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 2rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 2rem;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-3 {
  margin-top: 1em;
  margin-bottom: 1rem;
}

.my-4 {
  margin-top: 1.5em;
  margin-bottom: 1.5rem;
}

.my-5 {
  margin-top: 2em;
  margin-bottom: 2rem;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 1em;
}

.m-4 {
  margin: 1.5em;
}

.m-5 {
  margin: 2em;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.bold {
  font-weight: bold;
}

.bold-300 {
  font-weight: 300;
}

.bold-400 {
  font-weight: 400;
}

.bold-500 {
  font-weight: 500;
}

.bold-600 {
  font-weight: 600;
}

.bold-700 {
  font-weight: 700;
}

.bold-800 {
  font-weight: 800;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.underline {
  text-decoration: underline;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.no-wrap {
  text-wrap: nowrap;
}

.text-10 {
  font-size: 0.625rem;
  line-height: 110%;
  letter-spacing: 0.0625rem;
}

.text-12 {
  font-size: 0.75rem;
  line-height: 150%;
}

.text-14 {
  font-size: 0.875rem;
  line-height: 130%;
}

.text-16 {
  font-size: 1rem;
}

.text-18 {
  font-size: 1.125rem;
}

.text-20 {
  font-size: 1.25rem;
  letter-spacing: -0.03938rem;
}

.text-22 {
  font-size: 1.375rem;
  letter-spacing: -0.03938rem;
}

.text-24 {
  font-size: 1.5rem;
  letter-spacing: -0.045rem;
}

.text-26 {
  font-size: 1.625rem;
}

.text-28 {
  font-size: 1.75rem;
}

.text-30 {
  font-size: 1.875rem;
}

.text-32 {
  font-size: 2rem;
  line-height: 110%;
}

.text-36 {
  font-size: 2.25rem;
  line-height: 110%;
  letter-spacing: -0.045rem;
}

.letter-spacing-normal {
  letter-spacing: normal;
}

.line-height-130 {
  line-height: 130%;
}

.line-height-150 {
  line-height: 150%;
}